import { Injectable } from '@angular/core';

@Injectable()
export class CommonConfirmationDialogConfigService {

  constructor() {
  }

  public getYesTranslate(): string {
    return 'Yes IN SERV';
  }

  public getNoTranslate(): string {
    return 'No in serv';
  }

}
