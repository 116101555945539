import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { endpoints } from '../common/enpoints';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EnumService } from './enum.service';
import { RealtimeService } from './realtime.service';
import {
  ADMIN_ROLES,
  UserModel,
  InjectedAuthService
} from 'common-lib';

export const USER_STORAGE = 'easy-user';
export const AUTH_TOKEN_STORAGE = 'easy-auth-token';
export const REFRESH_TOKEN_STORAGE = 'easy-refresh-token';

export enum TokenType {
  AUTH,
  REFRESH
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends InjectedAuthService {

  public user$: BehaviorSubject<UserModel>;

  private authToken: string;
  private refreshToken: string;

  constructor(private router: Router,
              private enums: EnumService,
              private http: HttpClient,
              private realtime: RealtimeService) {
    super();
    this.initService();
  }

  /**
   * getter for private token variables
   * @param type
   */
  public getToken(type: TokenType) {
    switch (type) {
      case TokenType.AUTH:
        return this.authToken;
      case TokenType.REFRESH:
        return this.refreshToken;
    }
  }

  getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_STORAGE);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE);
  }


  /**
   * update user
   */
  public updateUser() {
    this.http.get(endpoints.auth.user).subscribe((user: UserModel) => {
      this.setUser(user);
    });
  }

  /**
   * refresh tokens
   */
  public refreshTokens() {
    return this.http.post(endpoints.auth.refresh, {
      refreshToken: this.getToken(TokenType.REFRESH)
    }).pipe(tap((res: any) => {
      this.updateAccessToken(res.accessToken);
      this.updateRefreshToken(res.refreshToken);
    }));
  }

  loginRequestOnly(email: string, password: string, code: string) {
    return this.http.post(endpoints.auth.login, {email, password, code});
  }

  /**
   * login
   * @param email
   * @param password
   */
  login(email: string, password: string, code: string): Observable<any> {
    return this.loginRequestOnly(email, password, code)
      .pipe(map((response: any) => {
          this.setUser(response.user);
          this.updateAccessToken(response.accessToken);
          this.updateRefreshToken(response.refreshToken);
          this.router.navigate(['/', 'admin']).then();
          return response.user;
        }),
        catchError((e) => {
          return throwError(e);
        }));
  }

  /**
   * logout
   */
  public logOutUser() {
    localStorage.removeItem(USER_STORAGE);
    localStorage.removeItem(AUTH_TOKEN_STORAGE);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE);
    this.authToken = null;
    this.refreshToken = null;
    this.router.navigate(['/']);
    this.user$.next(undefined);
  }

  /**
   * user behavior subject setter
   * @param user
   */
  public setUser(user) {
    this.user$.next(user);
    localStorage.setItem(USER_STORAGE, JSON.stringify(user));
  }

  /**
   * update auth token
   * @param newToken
   */
  public updateAccessToken(newToken: string) {
    this.authToken = newToken;
    localStorage.setItem(AUTH_TOKEN_STORAGE, newToken);
  }

  /**
   * upadte refresh token
   * @param newToken
   */
  public updateRefreshToken(newToken: string) {
    this.refreshToken = newToken;
    localStorage.setItem(REFRESH_TOKEN_STORAGE, newToken);
  }

  /**
   * init ably realtime connection
   */
  public initRealtimeConnection() {
    if (!this.realtime.connection) {
      this.http.get(endpoints.broadcasting.auth).subscribe((res: any) => {
        this.realtime.initConnection(res.ablyToken);
      });
    }
  }

  /**
   * init constructor call
   */
  private initService() {
    this.authToken = localStorage.getItem(AUTH_TOKEN_STORAGE);
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE);
    this.user$ = new BehaviorSubject<UserModel>(localStorage.getItem(USER_STORAGE)
      ? JSON.parse(localStorage.getItem(USER_STORAGE))
      : undefined);
  }

  public updateAdmin(adminData): Observable<any> {
    return this.http.put(endpoints.auth.updateAdmin, adminData);
  }

  public hasAdminRole(adminRole: ADMIN_ROLES): boolean {
    return this.user$?.value?.adminRoles.includes(adminRole);
  }
}
