import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Admin2FAService } from '../../services/admin2-fa.service';
import { SubscriberComponent } from '../../../shared/classes/subscriber-component';
import { NbDialogRef } from '@nebular/theme';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-admin2-fa-validation-dialog',
  templateUrl: './admin2-fa-validation-dialog.component.html',
  styleUrls: ['./admin2-fa-validation-dialog.component.scss']
})
export class Admin2FaValidationDialogComponent extends SubscriberComponent implements OnInit {
  public reAuthForm: FormGroup;
  public isSubmitting: boolean;
  public wasAuthError: boolean;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private dialog: NbDialogRef<Admin2FaValidationDialogComponent>,
              private admin2Fa: Admin2FAService) {
    super();
  }

  ngOnInit(): void {
    this.reAuthForm = this.fb.group({
      password: ['', [Validators.required]],
      code: ['', [Validators.required]]
    });
  }

  submit() {
    this.isSubmitting = true;
    this.subs.push(
      this.admin2Fa.reAuthentication(this.reAuthForm.getRawValue()).subscribe(res => {
        console.log(res);
        this.closeDialogWithResult(true);
      }, error => {
        this.wasAuthError = true;
        this.closeDialogWithResult(false);
      })
    );
  }

  async closeDialogWithResult(wasSuccessfullyReset: boolean) {
    this.isSubmitting = false;
    if (wasSuccessfullyReset) {
      this.dialog.close(true);
    } else {
      await of().pipe(delay(2000)).toPromise();
      this.dialog.close(false);
      this.authService.logOutUser();
    }
  }
}
