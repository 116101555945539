export const albyChannelKey = 'private:Admin';
export const euroCurrencyDivider = 100;
export const BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY = 'easy-backoffice-language-key';

export const fromEuroToCents = (euro: number) => {
  return euro * euroCurrencyDivider;
};

export const fromCentsToEuro = (cents: number) => {
  return cents / euroCurrencyDivider;
};
