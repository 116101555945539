import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-common-confirmation-dialog',
  templateUrl: './common-confirmation-dialog.component.html',
  styleUrls: ['./common-confirmation-dialog.component.scss'],
})
export class CommonConfirmationDialogComponent implements OnInit {

  public isSubmitting: boolean;
  private subs = [];
  @Input() title: string;
  @Input() text: string;
  @Input() yesText = 'yes';
  @Input() noText = 'no';
  @Input() revertSubmitButtonColors: boolean;

  @Input('isSubmitting')
  set setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  @Output() wasAccepted = new EventEmitter();

  constructor(protected dialogRef: NbDialogRef<CommonConfirmationDialogComponent>) {
  }

  ngOnInit() {
  }

  submit() {
    this.isSubmitting = true;
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.submit();
    }
  }
}
