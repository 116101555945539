import { Injectable } from '@angular/core';
import { CommonConfirmationDialogConfigService } from '../../common-modules/common-confirmation-dialog/common-confirmation-dialog-config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogConfigService extends CommonConfirmationDialogConfigService {

  constructor(private translate: TranslateService) {
    super();
  }

  public getYesTranslate(): string {
    return this.translate.instant('adminGlobal.confirmation.yes');
  }

  public getNoTranslate(): string {
    return this.translate.instant('adminGlobal.confirmation.no');
  }
}
