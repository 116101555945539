import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { CommonConfirmationDialogComponent } from './common-confirmation-dialog/common-confirmation-dialog.component';
import { CommonConfirmationDialogConfigService } from './common-confirmation-dialog-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonConfirmationDialogService {

  constructor(private nbDialogService: NbDialogService,
              private commonConfirmationDialogConfigService: CommonConfirmationDialogConfigService) {
  }

  openConfirmationDialog(title: string, text: string, confirmationYesText?: string, confirmationNoText?: string): Promise<boolean> {
    const yesText = confirmationYesText ?? this.commonConfirmationDialogConfigService.getYesTranslate();
    const noText = confirmationNoText ?? this.commonConfirmationDialogConfigService.getNoTranslate();

    const dialog = this.nbDialogService.open(CommonConfirmationDialogComponent, {
      context: {
        text,
        title,
        yesText,
        noText
      },
      closeOnBackdropClick: false
    });
    return dialog.onClose.toPromise();
  }
}
