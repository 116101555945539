import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbDatepickerModule, NbDialogModule, NbLayoutModule, NbThemeModule, NbTimepickerModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from './modules/shared/services/auth.service';
import { LanguageStateService } from './modules/shared/services/language-state.service';
import { EnumService } from './modules/shared/services/enum.service';
import { RealtimeService } from './modules/shared/services/realtime.service';
import { NbMomentDateModule } from '@nebular/moment';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonConfirmationDialogModule } from './modules/common-modules/common-confirmation-dialog/common-confirmation-dialog.module';
import { ConfirmationDialogConfigService } from './modules/shared/services/confirmation-dialog-config.service';
import { CommonConfirmationDialogConfigService } from './modules/common-modules/common-confirmation-dialog/common-confirmation-dialog-config.service';
import {
  appInitializerFactory,
  CommonBackbuttonHistoryModule,
  CommonRefreshTokenInterceptor,
  CommonToastHandlerInterceptor,
  CommonToastHandlerService,
  CommonTokenInterceptor,
  TRANSLATE_CATEGORIES
} from 'common-lib';
import { Router } from '@angular/router';
import { Admin2FaCheckInterceptor } from './modules/shared/interceptors/admin-2-fa-check.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { Admin2FAService } from './modules/admin/services/admin2-fa.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY } from '../config';
import { environment } from '../environments/environment';
import { AdminLocalityInterceptor } from './modules/shared/interceptors/admin-locality.interceptor';
import { AdminLocalityService } from './modules/admin/services/admin-locality.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.url}v2/free/translation/`, `?categories=${TRANSLATE_CATEGORIES.BO_ADMIN},${TRANSLATE_CATEGORIES.CATEGORY_SHARED_WEB},${TRANSLATE_CATEGORIES.ERRORS}`);
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NbThemeModule.forRoot({name: 'easy'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    CommonBackbuttonHistoryModule.forRoot(),
    NbMomentDateModule,
    NgxSkeletonLoaderModule.forRoot({animation: 'pulse', /* loadingText: 'This item is actually loading...'*/}),
    NbDialogModule.forRoot(),
    CommonConfirmationDialogModule.forRoot({
      configService: {
        provide: CommonConfirmationDialogConfigService,
        useExisting: ConfirmationDialogConfigService
      }
    }),
  ],
  providers: [
    RealtimeService,
    AdminLocalityService,
    {
      provide: APP_INITIALIZER,
      deps: [TranslateService],
      useFactory: (dep1) => {
        return appInitializerFactory(
          dep1,
          BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY,
          environment);
      },
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminLocalityInterceptor,
      deps: [AdminLocalityService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Admin2FaCheckInterceptor,
      deps: [Admin2FAService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonRefreshTokenInterceptor,
      deps: [AuthService, Router],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonTokenInterceptor,
      deps: [AuthService],
      multi: true
    },
    {
      provide: CommonToastHandlerService,
      deps: [ToastrService, TranslateService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonToastHandlerInterceptor,
      deps: [CommonToastHandlerService],
      multi: true
    },
    AuthService,
    LanguageStateService,
    EnumService
  ],
  exports: [
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
