<nb-card
  class="text-center"
  style="max-width: 400px">
  <nb-card-header>
    <span class="text-primary">{{title}}</span>
  </nb-card-header>
  <nb-card-body class="text-center">
    <div class="row">
      <div class="col">
        <span> {{text}} </span>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="footer_buttons">
      <div class="button_holder">
        <button nbButton
                size="small"
                shape="round"
                [status]="revertSubmitButtonColors? 'primary' : 'danger'" outline
                (click)="closeDialog()">
          {{noText}}
        </button>
      </div>
      <div class="button_holder">
        <button nbButton
                size="small"
                type="submit"
                shape="round"
                [status]="!revertSubmitButtonColors ? 'primary' : 'danger'" outline
                (click)="submit()"
                [nbSpinner]="isSubmitting">
          {{yesText}}
        </button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
