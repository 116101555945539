<nb-card>
  <nb-card-header>
    {{'adminReAuthentication.pleaseAuthenticate' | translate}}
  </nb-card-header>
  <nb-card-body>
    <h6 class="text-danger text-center" *ngIf="wasAuthError">
      {{'adminReAuthentication.Re-authenticationFailure' | translate}}
    </h6>
    <form [formGroup]="reAuthForm">
      <div class="form-group">
        <label for="heslo">{{ 'auth.passwordPlaceholder' | translate }}</label>
        <input nbInput fullWidth type="password" id="password" formControlName="password">
      </div>
        <div class="form-group">
          <label for="code">{{ 'auth.codePlaceholder' | translate }}</label>
          <input nbInput fullWidth type="text" id="code" formControlName="code"
                 autofill="off"
                 autocomplete="off"
          >
        </div>
      <div class="text-center">
        <button nbButton status="primary" [nbSpinner]="isSubmitting"
                type="submit"
                [disabled]="reAuthForm.invalid || isSubmitting || wasAuthError"
                (click)="submit()">
          {{ 'auth.login' | translate }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
