import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class SubscriberComponent implements OnDestroy {
  public subs: Subscription[] = [];

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
