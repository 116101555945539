import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '../common/enpoints';
import { filter, take, tap } from 'rxjs/operators';
import { OfferCategory } from '../../admin/modules/lookups/models/offer-category';


@Injectable()
export class EnumService {

  public allergensEnumSubject = new BehaviorSubject(null);
  public areasEnumSubject = new BehaviorSubject(null);
  public sitesEnumSubject = new BehaviorSubject(null);
  public offerCategoriesEnumSubject = new BehaviorSubject(null);
  public companiesEnumSubject = new BehaviorSubject(null);
  public deliveryTypeEnumSubject = new BehaviorSubject(null);


  constructor(private http: HttpClient) {
  }


  public getAllergensEnum(withoutCachedData?: boolean): Observable<any> {
    return concat(
      withoutCachedData ? of() : this.allergensEnumSubject.pipe(take(1)),
      this.http.get(endpoints.enums.allergens).pipe(tap(allergens => {
        this.allergensEnumSubject.next(allergens);
      }))
    )
      .pipe(filter(allergens => allergens != null));
  }

  public getAreasEnum(withoutCachedData?: boolean): Observable<any> {
    return concat(
      withoutCachedData ? of() : this.areasEnumSubject.pipe(take(1)),
      this.http.get(endpoints.enums.areas).pipe(tap(areas => {
        this.areasEnumSubject.next(areas);
      }))
    )
      .pipe(filter(areas => areas != null));
  }

  public getSitesEnum(withoutCachedData?: boolean): Observable<any> {
    return concat(
      withoutCachedData ? of() : this.sitesEnumSubject.pipe(take(1)),
      this.http.get(endpoints.enums.site).pipe(tap(sites => {
        this.sitesEnumSubject.next(sites);
      }))
    )
      .pipe(filter(sites => sites != null));
  }

  public getCompaniesEnum(withoutCachedData?: boolean): Observable<any> {
    return concat(
      withoutCachedData ? of() : this.companiesEnumSubject.pipe(take(1)),
      this.http.get(endpoints.enums.company).pipe(tap(companies => {
        this.companiesEnumSubject.next(companies);
      }))
    )
      .pipe(filter(companies => companies != null));
  }

  public getDeliveryTypeEnum(withoutCachedData?: boolean): Observable<any> {
    return concat(
      withoutCachedData ? of() : this.deliveryTypeEnumSubject.pipe(take(1)),
      this.http.get(endpoints.enums.deliveryType).pipe(tap(deliveryType => {
        this.deliveryTypeEnumSubject.next(deliveryType);
      }))
    )
      .pipe(filter(deliveryType => deliveryType != null));
  }

  public getOfferCategoriesEnum(withoutCachedData?: boolean): Observable<any> {
    return concat(
      withoutCachedData ? of() : this.offerCategoriesEnumSubject.pipe(take(1)),
      this.http.get<OfferCategory[]>(endpoints.enums.offerCategories).pipe(
        tap(categories => {
          this.offerCategoriesEnumSubject.next(categories.sort((a, b) => {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            return 0;
          }));
        })
      )
    )
      .pipe(filter(categories => categories != null));
  }

  resetAllEnums() {
    this.allergensEnumSubject.next(null);
    this.areasEnumSubject.next(null);
    this.sitesEnumSubject.next(null);
    this.offerCategoriesEnumSubject.next(null);
    this.companiesEnumSubject.next(null);
    this.deliveryTypeEnumSubject.next(null);
  }
}
