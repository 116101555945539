import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY } from '../../../../config';


@Injectable({
  providedIn: 'root'
})
export class LanguageStateService {

  constructor(private translateService: TranslateService) {
  }


  public changeLanguage() {
    if (this.translateService.currentLang === 'en') {
      this.translateService.use('fr');
      localStorage.setItem(BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY, 'fr');
    } else {
      this.translateService.use('en');
      localStorage.setItem(BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY, 'en');
    }
  }
}
