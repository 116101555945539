import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonConfirmationDialogComponent } from './common-confirmation-dialog/common-confirmation-dialog.component';
import { NbButtonModule, NbCardModule, NbDialogService, NbSpinnerModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { CommonConfirmationDialogService } from './common-confirmation-dialog.service';
import { CommonConfirmationDialogConfigService } from './common-confirmation-dialog-config.service';

@NgModule({
  declarations: [
    CommonConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    NbButtonModule,
    NbSpinnerModule,
    NbCardModule
  ],
})
export class CommonConfirmationDialogModule {
  static forRoot(config?: { configService?: Provider }): ModuleWithProviders<CommonConfirmationDialogModule> {
    return {
      ngModule: CommonConfirmationDialogModule,
      providers: [
        CommonConfirmationDialogService,
        config?.configService || {provide: CommonConfirmationDialogConfigService}
      ]
    } as ModuleWithProviders<CommonConfirmationDialogModule>;
  }
}
