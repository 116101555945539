import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {

  private subs: Subscription[] = [];

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public addSub(subs: Subscription[]) {
    subs.forEach((s: Subscription) => this.subs.push(s));
  }
}
