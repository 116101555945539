import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { BehaviorSubject, defer, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { Admin2FAService } from '../../admin/services/admin2-fa.service';

@Injectable()
export class Admin2FaCheckInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private fa2: Admin2FAService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      console.log('[2FA] INTERCEPTOR');
      // 1118 backend error code to 2fa
      if (err.status === 401 && err.error?.code === 1118) {
        console.log('[2FA] NEEDED');
        return this.handleRefreshTokenUnauthorized(request, next);
      }
      console.log('[2FA] NOT NEEDED');
      return throwError(err);
    }));
  }

  handleRefreshTokenUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      // const a = this.fa2.showValidationDialog();
      // console.log(a);
      return this.fa2.showValidationDialog().pipe(
        tap(res => {
          console.log('[2FA] DIALOG RESPONSE');
          console.log(res);
        }),
        switchMap(res => {
          console.log('[2FA] SWITCH MAP');
          console.log(res);
          return next.handle(req);
        }),
        finalize(() => {
          console.log('[2FA] FINALIZE');
          this.isRefreshingToken = false;
        })
      );

    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null)
        , take(1)
        , switchMap(token => {
          console.log('[2FA] DIALOG REQUEST IN PROGRESS');
          // return next.handle(this.addToken(req, token));
          return next.handle(req);
        })
      );
    }
  }
}
