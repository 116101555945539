import { environment } from '../../../../environments/environment';

const roleUrl: 'admin' | 'merchant' | 'delivery' | 'customer' = 'admin';

export const endpoints = {
  auth: {
    user: `${environment.url + roleUrl}/auth/user`,
    login: `${environment.url + roleUrl}/auth/login`,
    updateAdmin: `${environment.url + roleUrl}/user`,
    refresh: `${environment.url + roleUrl}/auth/refresh`
  },
  enums: {
    currencies: `${environment.url + roleUrl}/enum/currency`,
    allergens: `${environment.url + roleUrl}/enum/allergen`,
    areas: `${environment.url + roleUrl}/enum/area`,
    areasPaused: `${environment.url + roleUrl}/enum/area/pause`,
    site: `${environment.url + roleUrl}/enum/site`,
    offerCategories: `${environment.url + roleUrl}/enum/offer-category`,
    deliveryType: `${environment.url + roleUrl}/enum/delivery-type`,
    company: `${environment.url + roleUrl}/company`,
    deliveryIncomeCategory: `${environment.url + roleUrl}/enum/delivery-guy-status`
  },
  users: {
    customers: `${environment.url + roleUrl}/user/customer`,
    merchants: `${environment.url + roleUrl}/user/merchant`,
    deliverers: `${environment.url + roleUrl}/user/deliverer`,
    user: `${environment.url + roleUrl}/user/`,
    statistic: `${environment.url + roleUrl}/statistic/deliverer`,
    account: `${environment.url}/user/`
  },
  offers: {
    offers: `${environment.url + roleUrl}/offer`,
    allOffers: `${environment.url + roleUrl}/offer/all`,
    offer: `${environment.url + roleUrl}/offer/`,
    offerDuplicate: `${environment.url + roleUrl}/offer/duplicate`,
    incidents: `${environment.url + roleUrl}/offer/incidents`,
    offerIncidents: `${environment.url + roleUrl}/incident/offer/`,
    incident: `${environment.url + roleUrl}/offer/incident`,
    optionItem: `${environment.url + roleUrl}/offer/option-item`,
    createOffer: `${environment.url + roleUrl}/offer/basic`
  },
  incidents: {
    incident: `${environment.url + roleUrl}/incident`,
  },
  global: {
    getGlobal: `${environment.url + roleUrl}/setting`
  },
  orders: {
    incidents: `${environment.url + roleUrl}/incident`,
    orders: `${environment.url + roleUrl}/order`,
    statistic: `${environment.url + roleUrl}/statistic/order`
  },
  reports: {
    reportEvent: `${environment.url + roleUrl}/report-event`,
    userOnMap: `${environment.url + roleUrl}/user/customer-address`
  },
  dashboard: {
    dashboard: `${environment.url + roleUrl}/statistic/dashboard`
  },
  coupons: {
    coupons: `${environment.url + roleUrl}/coupon`
  },
  notifications: {
    massNotifications: `${environment.url + roleUrl}/mass-notification`
  },
  chat: {
    rooms: `${environment.url + roleUrl}/chat-backoffice/room`,
    chat: `${environment.url + roleUrl}/chat-backoffice`
  },
  broadcasting: {
    auth: `${environment.url + roleUrl}/broadcasting/auth`
  },
  auditLog: {
    logs: `${environment.url + roleUrl}/statistic/audit-log`
  },
  events: {
    events: `${environment.url + roleUrl}/carousel`
  }
};
