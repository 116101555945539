import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { interval, Observable } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { Admin2FaValidationDialogComponent } from '../components/admin2-fa-validation-dialog/admin2-fa-validation-dialog.component';
import { AuthService } from '../../shared/services/auth.service';
import { of } from 'rxjs';
import { debounce, delay, delayWhen, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Admin2FAService {

  constructor(private http: HttpClient,
              private authService: AuthService,
              private dialogService: NbDialogService) {
  }

  get2FASecret(): Observable<any> {
    return this.http.get(`${environment.url}v2/admin/account/2fa-secret`);
  }

  reAuthentication(data): Observable<any> {
    return this.http.post(`${environment.url}admin/auth/re-authentication`, data).pipe(tap(res => {
      this.authService.updateAccessToken(res.accessToken);
      this.authService.updateRefreshToken(res.refreshToken);
    }));
  }

  showValidationDialog(): Observable<boolean> {
    const dialog = this.dialogService.open(Admin2FaValidationDialogComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false
    });

    return dialog.onClose;
  }

}
