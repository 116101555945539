import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AdminLocalityService } from '../../admin/services/admin-locality.service';

@Injectable()
export class AdminLocalityInterceptor implements HttpInterceptor {

  constructor(private localityService: AdminLocalityService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentLocality = this.localityService.getCurrentLocalityValue();
    if (currentLocality && request.url.includes(environment.url)) {
      request = request.clone({
          setHeaders: {Locality: currentLocality.name}
        }
      );
    }

    return next.handle(request);
  }
}
