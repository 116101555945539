import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable, merge } from 'rxjs';
import { AdminLocality } from '../models/admin-locality';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, take, tap } from 'rxjs/operators';

export const ADMIN_SELECTED_LOCALITY_KEY = 'easy-admin-locality';

@Injectable()
export class AdminLocalityService {
  private currentLocality: BehaviorSubject<AdminLocality> = new BehaviorSubject<AdminLocality>(null);
  private availableLocalities: BehaviorSubject<AdminLocality[]> = new BehaviorSubject<AdminLocality[]>(null);

  constructor(private http: HttpClient) {
  }

  private getLocalityFromLs() {
    localStorage.getItem(ADMIN_SELECTED_LOCALITY_KEY);
  }

  public getCurrentLocalityValue(): AdminLocality | null {
    return this.currentLocality.value;
  }

  public setCurrentLocalityValue(locality: AdminLocality) {
    return this.currentLocality.next(locality);
  }

  public resetCurrentLocalityValue() {
    return this.currentLocality.next(null);
  }

  public getCurrentLocalityObs(): Observable<AdminLocality> {
    return this.currentLocality.asObservable();
  }

  public getAvailableLocalitiesObs(): Observable<AdminLocality[]> {
    return this.availableLocalities.asObservable();
  }

  getMyAvailableLocalities(): Observable<AdminLocality[]> {
    return merge<AdminLocality[]>(
      this.getAvailableLocalitiesObs().pipe(take(1)),
      this.http.get<AdminLocality[]>(`${environment.url}v2/admin/enum/locality`).pipe(tap(res => {
        this.availableLocalities.next(res);
      }))
    ).pipe(filter(res => res !== null));
  }

  getAllAvailableLocalities(): Observable<AdminLocality[]> {
    return this.http.get<AdminLocality[]>(`${environment.url}v2/free/locality`);
  }
}
